.todo-area {
  height: 100vh;
  width: 24vw;
  background-color: #ededed;
  border-left: 3px #dadbd3 solid;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.todo-area .leftArrowContainer .leftArrow {
  display: none;
  font-size: 3rem;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.todo-area:hover {
  background-color: #e5e6e4;
}

.todo-area .chat-header {
  height: 5vh;
  text-align: center;
  padding: 20px 10px;
}

.todo-area .chat-header h1 {
  margin-top: 0px !important;
}

.todo-area .taskContainer {
  height: 82vh;
  overflow: scroll;
}

.todo-area .taskContainer .task {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 3px solid #dadbd3;
  color: #3a3a3a;
  padding: 0px 10px;
  -webkit-transition: 0.3s !important;
  transition: 0.3s !important;
}

.todo-area .taskContainer .task .deleteBtn {
  cursor: pointer;
  padding: 3px;
  border-radius: 20px;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.todo-area .taskContainer .task .deleteBtn:hover {
  background-color: rgba(225, 255, 252, 0.4);
}

.todo-area .taskContainer .task h3 {
  padding-left: 20px;
  outline: none;
}

.todo-area .taskContainer .task:hover {
  background-color: #dadbd3;
  color: coral;
}

.todo-area .addTask {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  position: relative;
  height: 12vh;
}

.todo-area .addTask form {
  height: 3vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.todo-area .addTask form .input {
  height: 27px;
  width: 10vh;
  border: none;
  padding: 0 20px;
  font-size: 20px;
  background-color: #f1f1f1;
  border-radius: 20px 0 0 20px !important;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: #3a3a3a;
}

.todo-area .addTask form .input:hover {
  background-color: #e5ebeb;
}

.todo-area .addTask form .input:focus {
  outline: none;
  background-color: #e6e6e6;
}

.todo-area .addTask form .submit {
  height: 27px;
  border: none;
  border-radius: 0 20px 20px 0;
  width: 70px;
  background-color: #4cd49c;
  color: white;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.todo-area .addTask form .submit:hover {
  background-color: #4be0a2;
}

@media only screen and (max-width: 940px) {
  .leftArrow {
    display: block !important;
    font-size: 3rem;
  }
}

@media only screen and (max-width: 600px) {
  .addTask {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    width: 100vw !important;
  }
  .addTask form .input {
    width: 40vw !important;
  }
  .showTodoAreaMobile {
    display: block !important;
    width: 100vw !important;
  }
  .hideTodoAreaMobile {
    display: none !important;
  }
  .leftArrow {
    display: block !important;
    font-size: 3rem;
  }
  .taskContainer {
    height: 65vh !important;
  }
}
