.sidebar {
  margin: none !important;
  max-width: 16vw;
  min-width: 16vw;
  height: 100vh;
  background-color: #ededed;
  -webkit-box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.2);
          box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.sidebar:hover {
  background-color: #e5e6e4;
}

.sidebar .info {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 3px #dadbd3 solid;
  height: 10vh;
  color: #3a3a3a;
}

.sidebar .info:hover {
  cursor: pointer;
}

.sidebar .info h1 {
  width: 100%;
  font-size: 140%;
  text-align: center;
}

.sidebar .info .imgUrl {
  height: 80%;
  margin-left: 10%;
  border-radius: 40px;
}

.sidebar .info .rightArrowContainer {
  display: none;
  padding: 10px;
}

.sidebar .info .rightArrowContainer .rightArrow {
  font-size: 3rem;
  z-index: 30;
}

.sidebar .chatList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 3px #dadbd3 solid;
  color: #3a3a3a;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 65vh;
  overflow-y: scroll;
  width: 16vw;
}

.sidebar .chatList .chatRoomOption {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 16vw;
  color: #3a3a3a;
}

.sidebar .chatList .chatRoomOption:hover {
  cursor: pointer;
  background-color: #dadbd3;
}

.sidebar .bottomOptions {
  margin-top: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #3a3a3a;
}

.sidebar .bottomOptions .options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-bottom: 3px #dadbd3 solid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 100%;
  height: 8vh;
}

.sidebar .bottomOptions .options:hover {
  cursor: pointer;
  background-color: #dadbd3;
}

.sidebar .bottomOptions .settings {
  color: #929292 !important;
  background-color: #838282 !important;
}

.sidebar .bottomOptions .settings:hover {
  cursor: not-allowed !important;
}

.sidebar .bottomOptions .signOut {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-bottom: 3px #dadbd3 solid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 100%;
  height: 8vh;
}

.sidebar .bottomOptions .signOut:hover {
  cursor: pointer;
  background-color: #ff5757;
  color: #dadbd3;
}

.s-leftPadding {
  margin-left: 10px;
}

@media only screen and (max-width: 940px) {
  .sidebar {
    min-width: 40vw !important;
  }
  .info {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
    position: relative;
  }
  .info h1 {
    width: auto !important;
    font-size: 200% !important;
    text-align: center;
  }
  .info .imgUrl {
    border-radius: 40px !important;
    padding: 0 !important;
  }
  .chatList {
    width: 40vw !important;
  }
  .chatList .chatRoomOption {
    width: 40vw !important;
  }
}

@media only screen and (max-width: 600px) {
  .hideSidebarMobile {
    display: none !important;
  }
  .showSidebarMobile {
    display: block !important;
  }
  .sidebar {
    height: 100vh !important;
    min-width: 100vw !important;
  }
  .sidebar .info {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
    position: relative;
  }
  .sidebar .info h1 {
    width: auto !important;
    font-size: 200% !important;
    text-align: center;
  }
  .sidebar .info .imgUrl {
    border-radius: 40px !important;
    width: auto;
    height: 90%;
    padding: 0 !important;
  }
  .sidebar .info .rightArrowContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .sidebar .chatList {
    width: 100vw !important;
  }
  .sidebar .chatList .chatRoomOption {
    width: 100vw !important;
  }
  .sidebar .bottomOptions .options {
    width: 100%;
  }
  .sidebar .bottomOptions .signOut {
    width: 100%;
  }
}
