.settings-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100vw;
  height: 100vh;
}

.settings-page .container {
  width: 75vw;
  height: 75vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.settings-page .container form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.settings-page .container form .txf {
  max-width: 200px !important;
}

.settings-page .container .warning {
  color: #fd6a6a;
  font-size: 17px;
  max-width: 300px;
  text-align: center;
}

.settings-page .container .submit {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: none;
  font-size: 10px !important;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 200px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #73a4ff;
  color: #dadbd3;
}

.settings-page .container .submit:hover {
  cursor: pointer;
  background-color: #4888ff;
  color: #dadbd3;
}

.settings-page .container .signOut {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 200px;
  font-size: 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #fd6a6a;
  color: #dadbd3;
}

.settings-page .container .signOut:hover {
  cursor: pointer;
  background-color: #ff5656;
  color: #dadbd3;
}
