@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
body{
    margin: 0 0 0 0 !important;
    padding: 0 !important;
    /* overflow: hidden;  */
    scrollbar-width: none;
    font-family: 'Ubuntu', sans-serif;
}

::-webkit-scrollbar{
    display: none;
}
.chat-app{
    background-color: #dadbd3;
    display: flex;
 }
.settings-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.settings-page .container {
  width: 75vw;
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.settings-page .container form {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.settings-page .container form .txf {
  max-width: 200px !important;
}

.settings-page .container .warning {
  color: #fd6a6a;
  font-size: 17px;
  max-width: 300px;
  text-align: center;
}

.settings-page .container .submit {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  border: none;
  font-size: 10px !important;
  transition: 0.4s;
  width: 200px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #73a4ff;
  color: #dadbd3;
}

.settings-page .container .submit:hover {
  cursor: pointer;
  background-color: #4888ff;
  color: #dadbd3;
}

.settings-page .container .signOut {
  display: flex;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  transition: 0.4s;
  width: 200px;
  font-size: 10px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #fd6a6a;
  color: #dadbd3;
}

.settings-page .container .signOut:hover {
  cursor: pointer;
  background-color: #ff5656;
  color: #dadbd3;
}

.sidebar {
  margin: none !important;
  max-width: 16vw;
  min-width: 16vw;
  height: 100vh;
  background-color: #ededed;
  box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.sidebar:hover {
  background-color: #e5e6e4;
}

.sidebar .info {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 3px #dadbd3 solid;
  height: 10vh;
  color: #3a3a3a;
}

.sidebar .info:hover {
  cursor: pointer;
}

.sidebar .info h1 {
  width: 100%;
  font-size: 140%;
  text-align: center;
}

.sidebar .info .imgUrl {
  height: 80%;
  margin-left: 10%;
  border-radius: 40px;
}

.sidebar .info .rightArrowContainer {
  display: none;
  padding: 10px;
}

.sidebar .info .rightArrowContainer .rightArrow {
  font-size: 3rem;
  z-index: 30;
}

.sidebar .chatList {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 3px #dadbd3 solid;
  color: #3a3a3a;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 65vh;
  overflow-y: scroll;
  width: 16vw;
}

.sidebar .chatList .chatRoomOption {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  width: 16vw;
  color: #3a3a3a;
}

.sidebar .chatList .chatRoomOption:hover {
  cursor: pointer;
  background-color: #dadbd3;
}

.sidebar .bottomOptions {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #3a3a3a;
}

.sidebar .bottomOptions .options {
  display: flex;
  justify-content: center;
  border-bottom: 3px #dadbd3 solid;
  align-items: center;
  transition: 0.4s;
  width: 100%;
  height: 8vh;
}

.sidebar .bottomOptions .options:hover {
  cursor: pointer;
  background-color: #dadbd3;
}

.sidebar .bottomOptions .settings {
  color: #929292 !important;
  background-color: #838282 !important;
}

.sidebar .bottomOptions .settings:hover {
  cursor: not-allowed !important;
}

.sidebar .bottomOptions .signOut {
  display: flex;
  justify-content: center;
  border-bottom: 3px #dadbd3 solid;
  align-items: center;
  transition: 0.4s;
  width: 100%;
  height: 8vh;
}

.sidebar .bottomOptions .signOut:hover {
  cursor: pointer;
  background-color: #ff5757;
  color: #dadbd3;
}

.s-leftPadding {
  margin-left: 10px;
}

@media only screen and (max-width: 940px) {
  .sidebar {
    min-width: 40vw !important;
  }
  .info {
    align-items: center !important;
    position: relative;
  }
  .info h1 {
    width: auto !important;
    font-size: 200% !important;
    text-align: center;
  }
  .info .imgUrl {
    border-radius: 40px !important;
    padding: 0 !important;
  }
  .chatList {
    width: 40vw !important;
  }
  .chatList .chatRoomOption {
    width: 40vw !important;
  }
}

@media only screen and (max-width: 600px) {
  .hideSidebarMobile {
    display: none !important;
  }
  .showSidebarMobile {
    display: block !important;
  }
  .sidebar {
    height: 100vh !important;
    min-width: 100vw !important;
  }
  .sidebar .info {
    align-items: center !important;
    position: relative;
  }
  .sidebar .info h1 {
    width: auto !important;
    font-size: 200% !important;
    text-align: center;
  }
  .sidebar .info .imgUrl {
    border-radius: 40px !important;
    width: auto;
    height: 90%;
    padding: 0 !important;
  }
  .sidebar .info .rightArrowContainer {
    display: flex;
  }
  .sidebar .chatList {
    width: 100vw !important;
  }
  .sidebar .chatList .chatRoomOption {
    width: 100vw !important;
  }
  .sidebar .bottomOptions .options {
    width: 100%;
  }
  .sidebar .bottomOptions .signOut {
    width: 100%;
  }
}

body {
  overflow: hidden;
}

.chat-area {
  width: 60vw;
  height: 100vh;
  background-color: #dadbd3;
  display: block;
}

.chat-area .chat-header {
  height: 10vh;
  border-bottom: 3px #dadbd3 solid;
  display: flex;
  align-items: center;
  background-color: #ededed;
  border-left: 3px #dadbd3 solid;
  color: #3a3a3a;
  transition: 0.3s;
}

.chat-area .chat-header:hover {
  background-color: #e5e6e4;
}

.chat-area .chat-header .rightArrow {
  display: none;
}

.chat-area .chat-header .leftArrow {
  display: none;
}

.chat-area .chat-header .chatInfoContainer {
  padding-left: 5%;
  display: flex;
  align-items: center;
  width: 100%;
}

.chat-area .chat-header .chatInfoContainer div {
  padding-left: 5%;
  display: flex;
  align-items: center;
  width: 50%;
}

.chat-area .chat-header .chatInfoContainer h1 {
  margin-left: 10%;
}

.chat-area .chat-header .setting {
  margin-left: auto;
  margin-right: 5%;
  cursor: pointer;
  padding: 5px;
  border-radius: 20px;
  transition: 0.1s;
}

.chat-area .chat-header .setting:hover {
  background-color: rgba(190, 207, 206, 0.4);
}

.chat-area .chat-header .LeaveChat {
  margin-left: auto;
  margin-right: 5%;
  cursor: pointer;
  padding: 5px;
  border-radius: 20px;
  transition: 0.3s;
}

.chat-area .chat-header .LeaveChat:hover {
  color: #f85f5f;
  background-color: rgba(190, 207, 206, 0.4);
}

.chat-area .messages {
  height: 81vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.chat-area .messages .composedByMe {
  margin: 10px 10px 10px auto;
  max-width: 40%;
}

.chat-area .messages .composedByMe .messageImgText {
  background-color: #e6e6e6;
  box-shadow: 0px 0px 10px 10px rgba(255, 204, 181, 0.2);
  border-radius: 30px 0px 30px 30px;
  margin: 0 !important;
  display: flex;
  justify-content: start;
  flex-direction: row-reverse;
}

.chat-area .messages .composedByMe .messageImgText img {
  margin: 0 0 0 auto !important;
  border-radius: 100%;
  height: 40px;
  border-radius: 20px 0px 20px 20px;
}

.chat-area .messages .composedByMe .messageImgText h1 {
  max-width: 80%;
  display: inline-block;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: wrap;
  padding-right: 20px;
  margin: 10px 5px 10px 10px !important;
  font-size: 20px;
  color: #3a3a3a;
}

.chat-area .messages .composedByMe h6 {
  margin: 0 !important;
  font-size: 13px;
  color: #a09d9d;
}

.chat-area .messages .composedBySomeoneElse {
  margin: 10px 10px 10px 10px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.chat-area .messages .composedBySomeoneElse .messageImgText {
  background-color: #e6e6e6;
  box-shadow: 0px 0px 10px 10px rgba(181, 234, 255, 0.2);
  border-radius: 0 30px 30px 30px;
  margin: 0 !important;
  display: flex;
  justify-content: start;
}

.chat-area .messages .composedBySomeoneElse .messageImgText img {
  margin: 0 !important;
  border-radius: 100%;
  height: 40px;
  border-radius: 0 20px 20px 20px;
}

.chat-area .messages .composedBySomeoneElse .messageImgText h1 {
  max-width: 80%;
  display: inline-block;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: wrap;
  padding-right: 20px;
  margin: 10px 10px 10px 5px !important;
  font-size: 20px;
  color: #3a3a3a;
}

.chat-area .messages .composedBySomeoneElse h6 {
  margin: 0 !important;
  font-size: 13px;
  color: #a09d9d;
}

.chat-area .send {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  height: 8vh;
  width: 60vw;
}

.chat-area .send form {
  height: 3vh;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.chat-area .send form .input {
  height: 27px;
  width: 50vw;
  border: none;
  padding: 0 20px;
  font-size: 20px;
  background-color: #f1f1f1;
  border-radius: 20px 0 0 20px !important;
  transition: 0.3s;
  color: #3a3a3a;
}

.chat-area .send form .input:hover {
  background-color: #e5ebeb;
}

.chat-area .send form .input:focus {
  outline: none;
  background-color: #e6e6e6;
}

.chat-area .send form .submit {
  height: 27px;
  border: none;
  border-radius: 0 20px 20px 0;
  width: 70px;
  background-color: #4cd49c;
  color: white;
  transition: 0.1s;
}

.chat-area .send form .submit:hover {
  background-color: #4be0a2;
}

.clickAchatToStartMessagingContainer {
  display: block;
  position: relative;
  width: 100%;
}

.clickAchatToStartMessaging {
  color: dimgray;
  position: absolute;
  width: 300px;
  text-align: center;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -150px;
}

@media only screen and (max-width: 1000px) {
  .send form .input {
    width: 30vw !important;
  }
}

@media only screen and (max-width: 940px) {
  .chat-app {
    overflow: hidden !important;
    height: 100vh !important;
  }
  .send {
    width: 60vw !important;
  }
  .send form {
    height: 3vh;
  }
  .send form .input {
    width: 30vw !important;
  }
}

@media only screen and (max-width: 600px) {
  .chat-header {
    height: auto !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .chat-header .arrowsContainer {
    display: flex;
    justify-content: space-evenly !important;
    align-items: space-evenly !important;
  }
  .chat-header .arrowsContainer .rightArrow {
    display: block !important;
    font-size: 3rem;
  }
  .chat-header .arrowsContainer .leftArrow {
    display: block !important;
    font-size: 3rem;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  .chat-header .chatInfoContainer {
    padding-left: 5%;
    display: flex;
    align-items: center;
    width: 40%;
  }
  .chat-header .chatInfoContainer div {
    padding-left: 5%;
    display: flex;
    align-items: center;
    width: 50%;
  }
  .chat-header .chatInfoContainer h1 {
    margin-left: 10%;
  }
  .chat-area .chat-header .chatInfoContainer {
    padding-left: 1% !important;
  }
  .chat-area .chat-header .chatInfoContainer div {
    padding-left: 1% !important;
  }
  .chat-area .chat-header .chatInfoContainer h1 {
    font-size: 1.5rem !important;
  }
  .messages {
    height: 65vh !important;
  }
  .showChatAreaMobile {
    display: block !important;
    width: 100vw !important;
  }
  .hideChatAreaMobile {
    display: none !important;
  }
  .clickAchatToStartMessagingContainer {
    display: none !important;
  }
  .send {
    width: 100vw !important;
  }
  .send form {
    height: 3vh;
  }
  .send form .input {
    width: 50vw !important;
  }
}

.todo-area {
  height: 100vh;
  width: 24vw;
  background-color: #ededed;
  border-left: 3px #dadbd3 solid;
  transition: 0.3s;
}

.todo-area .leftArrowContainer .leftArrow {
  display: none;
  font-size: 3rem;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.todo-area:hover {
  background-color: #e5e6e4;
}

.todo-area .chat-header {
  height: 5vh;
  text-align: center;
  padding: 20px 10px;
}

.todo-area .chat-header h1 {
  margin-top: 0px !important;
}

.todo-area .taskContainer {
  height: 82vh;
  overflow: scroll;
}

.todo-area .taskContainer .task {
  display: flex;
  align-items: center;
  border-bottom: 3px solid #dadbd3;
  color: #3a3a3a;
  padding: 0px 10px;
  transition: 0.3s !important;
}

.todo-area .taskContainer .task .deleteBtn {
  cursor: pointer;
  padding: 3px;
  border-radius: 20px;
  transition: 0.1s;
}

.todo-area .taskContainer .task .deleteBtn:hover {
  background-color: rgba(225, 255, 252, 0.4);
}

.todo-area .taskContainer .task h3 {
  padding-left: 20px;
  outline: none;
}

.todo-area .taskContainer .task:hover {
  background-color: #dadbd3;
  color: coral;
}

.todo-area .addTask {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  height: 12vh;
}

.todo-area .addTask form {
  height: 3vh;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.todo-area .addTask form .input {
  height: 27px;
  width: 10vh;
  border: none;
  padding: 0 20px;
  font-size: 20px;
  background-color: #f1f1f1;
  border-radius: 20px 0 0 20px !important;
  transition: 0.3s;
  color: #3a3a3a;
}

.todo-area .addTask form .input:hover {
  background-color: #e5ebeb;
}

.todo-area .addTask form .input:focus {
  outline: none;
  background-color: #e6e6e6;
}

.todo-area .addTask form .submit {
  height: 27px;
  border: none;
  border-radius: 0 20px 20px 0;
  width: 70px;
  background-color: #4cd49c;
  color: white;
  transition: 0.1s;
}

.todo-area .addTask form .submit:hover {
  background-color: #4be0a2;
}

@media only screen and (max-width: 940px) {
  .leftArrow {
    display: block !important;
    font-size: 3rem;
  }
}

@media only screen and (max-width: 600px) {
  .addTask {
    display: flex !important;
    width: 100vw !important;
  }
  .addTask form .input {
    width: 40vw !important;
  }
  .showTodoAreaMobile {
    display: block !important;
    width: 100vw !important;
  }
  .hideTodoAreaMobile {
    display: none !important;
  }
  .leftArrow {
    display: block !important;
    font-size: 3rem;
  }
  .taskContainer {
    height: 65vh !important;
  }
}

#settingsPopup {
  position: fixed;
  z-index: 3 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

#settingsPopup .container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

#settingsPopup .container .background {
  display: flex;
  flex-direction: column;
  width: 80vw;
  height: 80vh;
  background-color: #dadbd3;
  overflow: scroll;
}

#settingsPopup .container .background .exit {
  cursor: pointer;
  margin: 20px 20px 0 auto;
}

#settingsPopup .container .background .exit:hover {
  color: #fd5454 !important;
}

#settingsPopup .container .background .container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#settingsPopup .container .background .container h1 {
  text-align: center;
}

#settingsPopup .container .background .container .sub-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  text-align: center;
  margin: none !important;
}

#settingsPopup .container .background .container .sub-container form {
  height: 80px;
  display: flex;
  align-items: center;
}

#settingsPopup .container .background .container .sub-container form h2 {
  margin: 0px 0 0 0 !important;
  width: 50%;
  text-align: left;
}

#settingsPopup .container .background .container .sub-container form .textField {
  margin-top: -15px;
}

#settingsPopup .container .background .container .sub-container form .inputField {
  margin-right: 5%;
}

#settingsPopup .container .background .container .sub-container form button {
  height: 30px !important;
  border-radius: 10px;
  border: none;
  font-size: 17px !important;
  transition: 0.4s;
  width: 100px;
  margin-right: 10px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #73a4ff;
  color: #dadbd3;
}

#settingsPopup .container .background .container .sub-container form button:hover {
  cursor: pointer;
  background-color: #4888ff;
  color: #dadbd3;
}

#settingsPopup .container .background .container .sub-container form .canDelete {
  background-color: #ff6565 !important;
}

#settingsPopup .container .background .container .sub-container form .canDelete:hover {
  background-color: #fd5454 !important;
}

#settingsPopup .container .background .container .sub-container form .notDelete {
  background-color: #aaa9a9 !important;
  cursor: not-allowed !important;
}

#settingsPopup .container .background .container .sub-container form .notDelete:hover {
  background-color: #949494 !important;
}

#settingsPopup .container .background .container .chatMembers {
  margin-bottom: 20%;
  background-color: #313131;
  color: #dadbd3;
}

#settingsPopup .container .background .container .chatMembers table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #313131;
}

#settingsPopup .container .background .container .chatMembers table th {
  background-color: #2a2a2a;
}

#settingsPopup .container .background .container .chatMembers table th, #settingsPopup .container .background .container .chatMembers table td {
  text-align: left;
  padding: 16px;
}

#settingsPopup .container .background .container .chatMembers table tr:nth-child(odd) {
  background-color: #414141;
}

#settingsPopup .darkBG {
  position: absolute;
  transition: 0.3s;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

#settingsPopup .darkBG:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.hidePopup {
  display: none !important;
}

.showPopup {
  display: block !important;
}

@media only screen and (max-width: 600px) {
  #settingsPopup .container .background {
    padding: 0 100px;
    overflow: scroll !important;
  }
}

.landingPage {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b7ddfb;
}

.landingPage .childDiv {
  width: 75vw;
  height: 100vh;
  display: flex;
  align-items: center;
}

.landingPage .childDiv .subChildDiv {
  height: 40vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.landingPage .childDiv .subChildDiv .title {
  z-index: 10 !important;
  width: 30%;
}

.landingPage .childDiv .subChildDiv .title .quickName {
  font-size: 7rem;
  color: #2196f3;
}

.landingPage .childDiv .subChildDiv .title .chatName {
  font-size: 6rem;
  color: white;
  background-color: #2196f3;
  border-radius: 30px 00px 30px 30px;
  padding-left: 20px;
  box-shadow: 0px 0px 20px 2px #2196f3;
}

.landingPage .childDiv .subChildDiv .desc {
  z-index: 10 !important;
  width: 60%;
}

.landingPage .childDiv .subChildDiv .desc p {
  font-size: 2rem;
  color: #fbfbfb;
}

.landingPage .childDiv .subChildDiv .desc .btnContainer {
  display: flex;
  width: 100%;
}

.landingPage .childDiv .subChildDiv .desc .btnContainer .buttonContact {
  transition: .3s;
  margin-left: 20px;
  width: 20%;
  height: 40px;
  border: none;
  background-color: #77b9f0;
  color: white;
  border-radius: 3px;
  box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.04), 0 2px 2px rgba(0, 0, 0, 0.25);
  font-size: 14px;
}

.landingPage .childDiv .subChildDiv .desc .btnContainer .buttonContact:hover {
  background: white;
  color: #757575;
  box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.25);
}

.landingPage .childDiv .subChildDiv .desc .btnContainer .buttonContact:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25), 0 0 0 3px #c8dafc;
}

.landingPage .childDiv .subChildDiv .desc .btnContainer .buttonContact:disabled {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.landingPage .childDiv .subChildDiv .desc .btnContainer .login-with-google-btn {
  transition: .2s;
  height: 40px;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.04), 0 2px 2px rgba(0, 0, 0, 0.25);
  color: #757575;
  font-size: 14px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}

.landingPage .childDiv .subChildDiv .desc .btnContainer .login-with-google-btn:hover {
  box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.25);
  background-color: #77b9f0;
  color: white;
}

.landingPage .childDiv .subChildDiv .desc .btnContainer .login-with-google-btn:active {
  background-color: #2196f3;
}

.landingPage .childDiv .subChildDiv .desc .btnContainer .login-with-google-btn:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25), 0 0 0 3px #c8dafc;
}

.landingPage .childDiv .subChildDiv .desc .btnContainer .login-with-google-btn:disabled {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.landingPage .bottomWaves {
  display: block;
  position: absolute;
  width: 100vw;
  height: auto;
  bottom: 200px;
}

.landingPage .bottomWaves svg {
  position: absolute;
}

.landingPage .topWaves {
  display: block;
  position: absolute;
  width: 100vw;
  height: auto;
  -webkit-transform: ScaleY(-1);
          transform: ScaleY(-1);
  top: 200px;
}

.landingPage .topWaves svg {
  position: absolute;
}

@media only screen and (max-width: 1100px) {
  .topWaves {
    -webkit-transform: rotate(90deg) !important;
            transform: rotate(90deg) !important;
    left: -75vw !important;
    bottom: 0 !important;
    top: 0 !important;
    right: 0 !important;
  }
  .bottomWaves {
    -webkit-transform: rotate(-90deg) !important;
            transform: rotate(-90deg) !important;
    left: 75vw !important;
    bottom: 0 !important;
    top: 0 !important;
    right: 0 !important;
  }
  .childDiv {
    display: block !important;
  }
  .subChildDiv {
    flex-direction: column !important;
  }
  .subChildDiv .title {
    width: 80% !important;
  }
  .subChildDiv .title .quickName {
    text-align: center;
    font-size: 10vw !important;
    width: 50% !important;
    background-color: white;
    box-shadow: 0px 0px 20px 2px white;
    border-radius: 00px 30px 30px 30px;
    padding: 5px 10px;
  }
  .subChildDiv .title .chatName {
    text-align: center;
    width: 40%;
    font-size: 10vw !important;
    margin-left: auto;
    padding: 5px 10px;
  }
  .subChildDiv .desc p {
    display: none !important;
  }
  .subChildDiv .desc .btnContainer {
    flex-direction: column;
  }
  .subChildDiv .desc .btnContainer .buttonContact {
    width: 100% !important;
    margin-top: 20px;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 900px) {
  body {
    background-color: #b7ddfb;
  }
  .topWaves {
    display: none !important;
  }
  .bottomWaves {
    display: none !important;
  }
  .landingPage {
    background-color: rgba(0, 0, 0, 0) !important;
    overflow: scroll !important;
    width: 100% !important;
  }
  .childDiv {
    display: block !important;
  }
  .subChildDiv {
    flex-direction: column !important;
  }
  .subChildDiv .title {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .subChildDiv .title .quickName {
    text-align: center;
    text-shadow: 2px 2px white;
    font-size: 23vw !important;
    width: 100% !important;
    background-color: rgba(0, 0, 0, 0) !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    border-radius: 0 !important;
    padding: 0 !important;
  }
  .subChildDiv .title .chatName {
    text-align: center;
    font-size: 20vw !important;
    text-shadow: 5px 5px #2196f3;
    width: 100% !important;
    background-color: rgba(0, 0, 0, 0) !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    border-radius: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .subChildDiv .desc {
    width: 100% !important;
  }
  .subChildDiv .desc p {
    display: none !important;
  }
  .subChildDiv .desc .btnContainer {
    flex-direction: column;
    margin-top: 20%;
    width: 100% !important;
  }
  .subChildDiv .desc .btnContainer .buttonContact {
    width: 100% !important;
    margin-top: 20px;
    margin-left: 0 !important;
  }
}

.cjRoom-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  overflow: scroll !important;
  height: 100vh;
}

.cjRoom-page .container {
  width: 75vw;
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cjRoom-page .container h2 {
  text-align: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.cjRoom-page .container h2 {
  text-align: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.cjRoom-page .container form {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.cjRoom-page .container form .txf {
  max-width: 200px !important;
}

.cjRoom-page .container .backBtn {
  margin: 20px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  border: none;
  font-size: 10px !important;
  transition: 0.4s;
  width: 200px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #ff5757;
  color: #dadbd3;
}

.cjRoom-page .container .backBtn:hover {
  cursor: pointer;
  background-color: #d14747;
  color: #dadbd3;
}

.cjRoom-page .container .warning {
  color: #fd6a6a;
  font-size: 17px;
  max-width: 300px;
  text-align: center;
}

.cjRoom-page .container .submit {
  margin: 20px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  border: none;
  font-size: 10px !important;
  transition: 0.4s;
  width: 200px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #7375ff;
  color: white;
}

.cjRoom-page .container .submit:hover {
  cursor: pointer;
  background-color: #5e60da;
  color: white;
}

.cjRoom-page .container .signOut {
  display: flex;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  transition: 0.4s;
  width: 200px;
  font-size: 10px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #fd6a6a;
  color: white;
}

.cjRoom-page .container .signOut:hover {
  cursor: pointer;
  background-color: #ff5656;
  color: #dadbd3;
}

