body {
  overflow: hidden;
}

.chat-area {
  width: 60vw;
  height: 100vh;
  background-color: #dadbd3;
  display: block;
}

.chat-area .chat-header {
  height: 10vh;
  border-bottom: 3px #dadbd3 solid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #ededed;
  border-left: 3px #dadbd3 solid;
  color: #3a3a3a;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.chat-area .chat-header:hover {
  background-color: #e5e6e4;
}

.chat-area .chat-header .rightArrow {
  display: none;
}

.chat-area .chat-header .leftArrow {
  display: none;
}

.chat-area .chat-header .chatInfoContainer {
  padding-left: 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.chat-area .chat-header .chatInfoContainer div {
  padding-left: 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 50%;
}

.chat-area .chat-header .chatInfoContainer h1 {
  margin-left: 10%;
}

.chat-area .chat-header .setting {
  margin-left: auto;
  margin-right: 5%;
  cursor: pointer;
  padding: 5px;
  border-radius: 20px;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.chat-area .chat-header .setting:hover {
  background-color: rgba(190, 207, 206, 0.4);
}

.chat-area .chat-header .LeaveChat {
  margin-left: auto;
  margin-right: 5%;
  cursor: pointer;
  padding: 5px;
  border-radius: 20px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.chat-area .chat-header .LeaveChat:hover {
  color: #f85f5f;
  background-color: rgba(190, 207, 206, 0.4);
}

.chat-area .messages {
  height: 81vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.chat-area .messages .composedByMe {
  margin: 10px 10px 10px auto;
  max-width: 40%;
}

.chat-area .messages .composedByMe .messageImgText {
  background-color: #e6e6e6;
  -webkit-box-shadow: 0px 0px 10px 10px rgba(255, 204, 181, 0.2);
          box-shadow: 0px 0px 10px 10px rgba(255, 204, 181, 0.2);
  border-radius: 30px 0px 30px 30px;
  margin: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.chat-area .messages .composedByMe .messageImgText img {
  margin: 0 0 0 auto !important;
  border-radius: 100%;
  height: 40px;
  border-radius: 20px 0px 20px 20px;
}

.chat-area .messages .composedByMe .messageImgText h1 {
  max-width: 80%;
  display: inline-block;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: wrap;
  padding-right: 20px;
  margin: 10px 5px 10px 10px !important;
  font-size: 20px;
  color: #3a3a3a;
}

.chat-area .messages .composedByMe h6 {
  margin: 0 !important;
  font-size: 13px;
  color: #a09d9d;
}

.chat-area .messages .composedBySomeoneElse {
  margin: 10px 10px 10px 10px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.chat-area .messages .composedBySomeoneElse .messageImgText {
  background-color: #e6e6e6;
  -webkit-box-shadow: 0px 0px 10px 10px rgba(181, 234, 255, 0.2);
          box-shadow: 0px 0px 10px 10px rgba(181, 234, 255, 0.2);
  border-radius: 0 30px 30px 30px;
  margin: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
}

.chat-area .messages .composedBySomeoneElse .messageImgText img {
  margin: 0 !important;
  border-radius: 100%;
  height: 40px;
  border-radius: 0 20px 20px 20px;
}

.chat-area .messages .composedBySomeoneElse .messageImgText h1 {
  max-width: 80%;
  display: inline-block;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: wrap;
  padding-right: 20px;
  margin: 10px 10px 10px 5px !important;
  font-size: 20px;
  color: #3a3a3a;
}

.chat-area .messages .composedBySomeoneElse h6 {
  margin: 0 !important;
  font-size: 13px;
  color: #a09d9d;
}

.chat-area .send {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  position: relative;
  height: 8vh;
  width: 60vw;
}

.chat-area .send form {
  height: 3vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.chat-area .send form .input {
  height: 27px;
  width: 50vw;
  border: none;
  padding: 0 20px;
  font-size: 20px;
  background-color: #f1f1f1;
  border-radius: 20px 0 0 20px !important;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: #3a3a3a;
}

.chat-area .send form .input:hover {
  background-color: #e5ebeb;
}

.chat-area .send form .input:focus {
  outline: none;
  background-color: #e6e6e6;
}

.chat-area .send form .submit {
  height: 27px;
  border: none;
  border-radius: 0 20px 20px 0;
  width: 70px;
  background-color: #4cd49c;
  color: white;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.chat-area .send form .submit:hover {
  background-color: #4be0a2;
}

.clickAchatToStartMessagingContainer {
  display: block;
  position: relative;
  width: 100%;
}

.clickAchatToStartMessaging {
  color: dimgray;
  position: absolute;
  width: 300px;
  text-align: center;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -150px;
}

@media only screen and (max-width: 1000px) {
  .send form .input {
    width: 30vw !important;
  }
}

@media only screen and (max-width: 940px) {
  .chat-app {
    overflow: hidden !important;
    height: 100vh !important;
  }
  .send {
    width: 60vw !important;
  }
  .send form {
    height: 3vh;
  }
  .send form .input {
    width: 30vw !important;
  }
}

@media only screen and (max-width: 600px) {
  .chat-header {
    height: auto !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .chat-header .arrowsContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
    -webkit-box-align: space-evenly !important;
        -ms-flex-align: space-evenly !important;
            align-items: space-evenly !important;
  }
  .chat-header .arrowsContainer .rightArrow {
    display: block !important;
    font-size: 3rem;
  }
  .chat-header .arrowsContainer .leftArrow {
    display: block !important;
    font-size: 3rem;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  .chat-header .chatInfoContainer {
    padding-left: 5%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 40%;
  }
  .chat-header .chatInfoContainer div {
    padding-left: 5%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 50%;
  }
  .chat-header .chatInfoContainer h1 {
    margin-left: 10%;
  }
  .chat-area .chat-header .chatInfoContainer {
    padding-left: 1% !important;
  }
  .chat-area .chat-header .chatInfoContainer div {
    padding-left: 1% !important;
  }
  .chat-area .chat-header .chatInfoContainer h1 {
    font-size: 1.5rem !important;
  }
  .messages {
    height: 65vh !important;
  }
  .showChatAreaMobile {
    display: block !important;
    width: 100vw !important;
  }
  .hideChatAreaMobile {
    display: none !important;
  }
  .clickAchatToStartMessagingContainer {
    display: none !important;
  }
  .send {
    width: 100vw !important;
  }
  .send form {
    height: 3vh;
  }
  .send form .input {
    width: 50vw !important;
  }
}
