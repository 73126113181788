#settingsPopup {
  position: fixed;
  z-index: 3 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100vw;
  height: 100vh;
}

#settingsPopup .container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#settingsPopup .container .background {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 80vw;
  height: 80vh;
  background-color: #dadbd3;
  overflow: scroll;
}

#settingsPopup .container .background .exit {
  cursor: pointer;
  margin: 20px 20px 0 auto;
}

#settingsPopup .container .background .exit:hover {
  color: #fd5454 !important;
}

#settingsPopup .container .background .container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

#settingsPopup .container .background .container h1 {
  text-align: center;
}

#settingsPopup .container .background .container .sub-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: auto;
  text-align: center;
  margin: none !important;
}

#settingsPopup .container .background .container .sub-container form {
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

#settingsPopup .container .background .container .sub-container form h2 {
  margin: 0px 0 0 0 !important;
  width: 50%;
  text-align: left;
}

#settingsPopup .container .background .container .sub-container form .textField {
  margin-top: -15px;
}

#settingsPopup .container .background .container .sub-container form .inputField {
  margin-right: 5%;
}

#settingsPopup .container .background .container .sub-container form button {
  height: 30px !important;
  border-radius: 10px;
  border: none;
  font-size: 17px !important;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 100px;
  margin-right: 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #73a4ff;
  color: #dadbd3;
}

#settingsPopup .container .background .container .sub-container form button:hover {
  cursor: pointer;
  background-color: #4888ff;
  color: #dadbd3;
}

#settingsPopup .container .background .container .sub-container form .canDelete {
  background-color: #ff6565 !important;
}

#settingsPopup .container .background .container .sub-container form .canDelete:hover {
  background-color: #fd5454 !important;
}

#settingsPopup .container .background .container .sub-container form .notDelete {
  background-color: #aaa9a9 !important;
  cursor: not-allowed !important;
}

#settingsPopup .container .background .container .sub-container form .notDelete:hover {
  background-color: #949494 !important;
}

#settingsPopup .container .background .container .chatMembers {
  margin-bottom: 20%;
  background-color: #313131;
  color: #dadbd3;
}

#settingsPopup .container .background .container .chatMembers table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #313131;
}

#settingsPopup .container .background .container .chatMembers table th {
  background-color: #2a2a2a;
}

#settingsPopup .container .background .container .chatMembers table th, #settingsPopup .container .background .container .chatMembers table td {
  text-align: left;
  padding: 16px;
}

#settingsPopup .container .background .container .chatMembers table tr:nth-child(odd) {
  background-color: #414141;
}

#settingsPopup .darkBG {
  position: absolute;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

#settingsPopup .darkBG:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.hidePopup {
  display: none !important;
}

.showPopup {
  display: block !important;
}

@media only screen and (max-width: 600px) {
  #settingsPopup .container .background {
    padding: 0 100px;
    overflow: scroll !important;
  }
}
