@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
body{
    margin: 0 0 0 0 !important;
    padding: 0 !important;
    /* overflow: hidden;  */
    scrollbar-width: none;
    font-family: 'Ubuntu', sans-serif;
}

::-webkit-scrollbar{
    display: none;
}
.chat-app{
    background-color: #dadbd3;
    display: flex;
 }