.landingPage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #b7ddfb;
}

.landingPage .childDiv {
  width: 75vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.landingPage .childDiv .subChildDiv {
  height: 40vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.landingPage .childDiv .subChildDiv .title {
  z-index: 10 !important;
  width: 30%;
}

.landingPage .childDiv .subChildDiv .title .quickName {
  font-size: 7rem;
  color: #2196f3;
}

.landingPage .childDiv .subChildDiv .title .chatName {
  font-size: 6rem;
  color: white;
  background-color: #2196f3;
  border-radius: 30px 00px 30px 30px;
  padding-left: 20px;
  -webkit-box-shadow: 0px 0px 20px 2px #2196f3;
          box-shadow: 0px 0px 20px 2px #2196f3;
}

.landingPage .childDiv .subChildDiv .desc {
  z-index: 10 !important;
  width: 60%;
}

.landingPage .childDiv .subChildDiv .desc p {
  font-size: 2rem;
  color: #fbfbfb;
}

.landingPage .childDiv .subChildDiv .desc .btnContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.landingPage .childDiv .subChildDiv .desc .btnContainer .buttonContact {
  -webkit-transition: .3s;
  transition: .3s;
  margin-left: 20px;
  width: 20%;
  height: 40px;
  border: none;
  background-color: #77b9f0;
  color: white;
  border-radius: 3px;
  -webkit-box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.04), 0 2px 2px rgba(0, 0, 0, 0.25);
          box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.04), 0 2px 2px rgba(0, 0, 0, 0.25);
  font-size: 14px;
}

.landingPage .childDiv .subChildDiv .desc .btnContainer .buttonContact:hover {
  background: white;
  color: #757575;
  -webkit-box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.25);
          box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.25);
}

.landingPage .childDiv .subChildDiv .desc .btnContainer .buttonContact:focus {
  outline: none;
  -webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25), 0 0 0 3px #c8dafc;
          box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25), 0 0 0 3px #c8dafc;
}

.landingPage .childDiv .subChildDiv .desc .btnContainer .buttonContact:disabled {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  background-color: #ebebeb;
  -webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
          box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.landingPage .childDiv .subChildDiv .desc .btnContainer .login-with-google-btn {
  -webkit-transition: .2s;
  transition: .2s;
  height: 40px;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  -webkit-box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.04), 0 2px 2px rgba(0, 0, 0, 0.25);
          box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.04), 0 2px 2px rgba(0, 0, 0, 0.25);
  color: #757575;
  font-size: 14px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}

.landingPage .childDiv .subChildDiv .desc .btnContainer .login-with-google-btn:hover {
  -webkit-box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.25);
          box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.25);
  background-color: #77b9f0;
  color: white;
}

.landingPage .childDiv .subChildDiv .desc .btnContainer .login-with-google-btn:active {
  background-color: #2196f3;
}

.landingPage .childDiv .subChildDiv .desc .btnContainer .login-with-google-btn:focus {
  outline: none;
  -webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25), 0 0 0 3px #c8dafc;
          box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25), 0 0 0 3px #c8dafc;
}

.landingPage .childDiv .subChildDiv .desc .btnContainer .login-with-google-btn:disabled {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  background-color: #ebebeb;
  -webkit-box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
          box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.landingPage .bottomWaves {
  display: block;
  position: absolute;
  width: 100vw;
  height: auto;
  bottom: 200px;
}

.landingPage .bottomWaves svg {
  position: absolute;
}

.landingPage .topWaves {
  display: block;
  position: absolute;
  width: 100vw;
  height: auto;
  -webkit-transform: ScaleY(-1);
          transform: ScaleY(-1);
  top: 200px;
}

.landingPage .topWaves svg {
  position: absolute;
}

@media only screen and (max-width: 1100px) {
  .topWaves {
    -webkit-transform: rotate(90deg) !important;
            transform: rotate(90deg) !important;
    left: -75vw !important;
    bottom: 0 !important;
    top: 0 !important;
    right: 0 !important;
  }
  .bottomWaves {
    -webkit-transform: rotate(-90deg) !important;
            transform: rotate(-90deg) !important;
    left: 75vw !important;
    bottom: 0 !important;
    top: 0 !important;
    right: 0 !important;
  }
  .childDiv {
    display: block !important;
  }
  .subChildDiv {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .subChildDiv .title {
    width: 80% !important;
  }
  .subChildDiv .title .quickName {
    text-align: center;
    font-size: 10vw !important;
    width: 50% !important;
    background-color: white;
    -webkit-box-shadow: 0px 0px 20px 2px white;
            box-shadow: 0px 0px 20px 2px white;
    border-radius: 00px 30px 30px 30px;
    padding: 5px 10px;
  }
  .subChildDiv .title .chatName {
    text-align: center;
    width: 40%;
    font-size: 10vw !important;
    margin-left: auto;
    padding: 5px 10px;
  }
  .subChildDiv .desc p {
    display: none !important;
  }
  .subChildDiv .desc .btnContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .subChildDiv .desc .btnContainer .buttonContact {
    width: 100% !important;
    margin-top: 20px;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 900px) {
  body {
    background-color: #b7ddfb;
  }
  .topWaves {
    display: none !important;
  }
  .bottomWaves {
    display: none !important;
  }
  .landingPage {
    background-color: rgba(0, 0, 0, 0) !important;
    overflow: scroll !important;
    width: 100% !important;
  }
  .childDiv {
    display: block !important;
  }
  .subChildDiv {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .subChildDiv .title {
    width: 100% !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .subChildDiv .title .quickName {
    text-align: center;
    text-shadow: 2px 2px white;
    font-size: 23vw !important;
    width: 100% !important;
    background-color: rgba(0, 0, 0, 0) !important;
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    border-radius: 0 !important;
    padding: 0 !important;
  }
  .subChildDiv .title .chatName {
    text-align: center;
    font-size: 20vw !important;
    text-shadow: 5px 5px #2196f3;
    width: 100% !important;
    background-color: rgba(0, 0, 0, 0) !important;
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    border-radius: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .subChildDiv .desc {
    width: 100% !important;
  }
  .subChildDiv .desc p {
    display: none !important;
  }
  .subChildDiv .desc .btnContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 20%;
    width: 100% !important;
  }
  .subChildDiv .desc .btnContainer .buttonContact {
    width: 100% !important;
    margin-top: 20px;
    margin-left: 0 !important;
  }
}
